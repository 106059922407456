import "./styles/bootstrap/dist/css/bootstrap.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
//import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Router basename={baseUrl}>
    <App />
  </Router>
);

//registerServiceWorker();
