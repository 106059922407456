import React, { Fragment } from "react";
import { Spinner } from "reactstrap";

const CustomSpinner = (props) => {
  return (
    <Fragment>
      {props.width && props.height ? (
        <div hidden={props.hidden}>
          <Spinner
            style={{
              width: props.width,
              height: props.height,
              marginLeft: "1rem",
            }}
            color="info"
          />
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <Spinner style={{ width: "7.5rem", height: "7.5rem" }} color="info" />
        </div>
      )}
    </Fragment>
  );
};

export default CustomSpinner;
