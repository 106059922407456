import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import CustomSpinner from './components/CustomSpinner/CustomSpinner';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import './styles/styles.css';

// Components
const Layout = lazy(() => import('./components/Layout/Layout'));

// Pages
const Login = lazy(() => import('./pages/Login/Login'));

const App = () => {
  return (
    <Suspense fallback={<CustomSpinner />}>
      <Switch>
        <Route exact path='/login' render={props => <Login {...props} />} />
        <ProtectedRoute path='/' component={Layout} />
      </Switch>
    </Suspense>
  );
};

export default App;